
body {
  font-family: "Mulish", sans-serif;
  margin: 0;
  /* background-color: bisque; */
  background-color: rgba(255,228,196, 0.3) !important; 
}

.container-home {
  font-size: 9.5em;
  width: 80%;
  margin: 2em auto;
  height: 100vh;
}


.container {
  width: 80%;
  margin: 5em auto;
  line-height: 25px;
}

h5 {
  font-size: 18px;
}